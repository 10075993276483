.MuiTableSortLabel-icon {
  color: "#FFFFFF" !important;
}
.navbar_conatiner {
  background-color: var(--main-color) !important;
  padding: 0 !important;
}
.makeStyles-listName-93 {
  font-family: "Gothic";
  color: red;
}
.nav_dpName {
  font-size: 18px !important;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.1rem;
  margin-top: 12px;
  color: rgba(255, 255, 255, 1);
  font-family: "Gothic";
}
.nav_dpName span {
  font-size: 14px;
  text-transform: capitalize;
  margin-right: 3px;
}
.nav_dpcreditamt {
  font-size: 16px;
  font-weight: 800;
  margin-top: -12px;
  color: rgba(67, 250, 67, 0.836);
  font-family: "Gothic";
}
.nav_dpcreditamt span {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.555);
  margin-right: 5px;
}
.navbar_conatiner h3 {
  margin-right: 8px;
  font-weight: 900;
  letter-spacing: 4px;
  color: rgba(0, 0, 0, 0.911);
  text-transform: uppercase;
}
.navbar_conatiner h3 span {
  color: #ffb219e3;
}
.MuiList-padding{
  padding: 0px !important;

}
.listLink {
  /* color: #35a2eb !important */
  color: var(--main-color);
}
.listLink:hover {
  /* color: #1480c9 !important; */
  color: var(--main-hover);
}
/* header */

.logo_conatiner {
  margin-top: 12px;
  width: 220px;
  height: 45px;
  overflow: hidden;
}
.logo_conatiner img {
  width: 170px;
  height: 30px;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .logo_conatiner {
    margin-top: 0px;
    width: 110px;
    height: max-content;
    overflow: hidden;
   
  
  }
  .logo_conatiner img {
    width: 100px;
    height: 60px;
    
  }



  .nav_dpName {
    font-size: 12px;
  }

  .nav_dpcreditamt {
    font-size: 12px;
  }
}
