.SahoAddCredit_conatiner {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8%;
}

.SahoAddCredit_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 90%;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.SahoAddCredit_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
  /* color: rgba(255, 255, 255, 0.692); */
  cursor: none;
}

.SahoAddCredit_table tbody tr td input {
  margin-top: 10px;
}
.row_add {
  display: flex;
}
.row_col {
  width: 50%;
  padding-right: 1%;
}
.row_col2 {
  width: 50%;
}
.SahoAddCredit_btn {
  font-size: 13px;
  border-radius: 0.2rem;
  color: white;
  text-transform: uppercase;
  padding: 8px;
  height: 10%;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  background-color: var(--main-btncolor) !important;
}
.modal_btn Button {
  font-size: 13px;
  border-radius: 0.2rem;
  color: white !important;
  text-transform: uppercase;
  padding: 8px;
  height: 10%;
  font-weight: 700;
  letter-spacing: 3px;
  border: none !important;
  background-color: var(--main-btncolor) !important;
}
.modal_btn Button:hover {
  background-color: var(--main-btncolor-hover) !important;
}
.SahoAddCredit_btn:hover {
  background-color: var(--main-btncolor-hover) !important;
}
.SahoAddCredit_subTable input {
  outline: none;
  border-radius: 0.2rem;
  border: 0.5px solid gainsboro;
  padding: 2px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.SahoAddCredit_subTable input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal_body {
  display: flex;
  justify-content: space-between;
}
.modal_body input {
  outline: none;
  width: 60%;

  border-radius: 0.2rem;
  border: 0.5px solid gainsboro;
  padding: 3px;
}
.modal_body label {
  font-family: "Gothic" !important  ;
}
.modal_head {
  font-family: "Gothic" !important  ;
  font-weight: 700;
}

.modal_payment label {
  margin-right: 5%;
}
.modal_payment input {
  margin-right: 1%;
}
@media only screen and (max-width: 768px) {
  .SahoAddCredit_subTable thead tr th {
    font-size: 12px;
  }
  .SahoAddCredit_btn {
    font-size: 8px;
    height: 5%;
  }

  .SahoAddCredit_table h3 {
    font-size: 15px;
  }
  .addCircleIcon_tax,
  .editIcon_tax,
  .saveIcon_tax {
    font-size: 20px !important;
  }
  .SahoAddCredit_btn Button {
    width: 60%;
    font-size: 12px;
  }

  .SahoAddCredit_subTable thead tr th {
    font-size: 12px;
  }

  .SahoAddCredit_subTable tbody tr th {
    font-size: 12px;
  }
  .box label{
    font-size: 12px;

  }
}
