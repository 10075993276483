.createBooking_conatiner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createBooking_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 80%;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}

.create_booking {
  /* color: #2b8ac9; */
  color: var(--main-color);
  text-align: center;
  margin: 15px 0 20px 0;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.booking_details {
  width: 100%;
  height: 45px;
  background-color: rgba(211, 211, 211, 0.24);
  display: flex;
  align-items: center;
}
.booking_details h4 {
  color: rgba(0, 0, 0, 0.733);
  margin-left: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 20px;
}
.bookingDetails_table {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row_add_booking {
  display: flex;
}
.add_Passenger_btn {
  color: var(--main-color) !important;
  background-color: rgba(117, 215, 245, 0.164) !important;
  text-transform: capitalize !important;
}
.modal_bodybooking {
  display: flex;
  justify-content: space-between;
}
.modal_bodybooking input {
  outline: none;
  width: 60%;
  border-radius: 0.2rem;
  border: 0.5px solid gainsboro;
  padding: 3px;
}
.modal_bodybooking label {
  font-family: "Gothic" !important  ;
}
.modal_bodybooking_head {
  display: flex;
  justify-content: space-between;
}
/* for all textfeilds */
.textField {
  width: 35ch;
  margin: 10px !important;
}
.textField_main {
  width: 60%;
  font-size: 12px !important;
}
.createBooking_conatiner p {
  color: red;
  font-size: 14px;
}
.personalDetails_banner {
  width: 100%;
  height: 45px;
  background-color: rgba(211, 211, 211, 0.24);
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
}
.personalDetails_banner h4 {
  color: rgba(0, 0, 0, 0.733);
  margin-left: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 20px;
}

.personalDetails_table {
  padding: 20px;
}

.createbooking_btn {
  margin-top: 22px;
  text-align: center;
  width: 100%;
}
.createbooking_btn Button {
  width: 40%;
  height: 50px;
  font-size: 22px;
  color: white;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  background-color: var(--main-btncolor) !important;
  font-family: "Gothic";
}

.editPersonalDetails_Cbooking .editPersonalDetails_Input {
  width: 40%;
  height: 35px;
  outline: none;
  border: 1px solid gainsboro;
  border-radius: 0.2rem;
}
.editPersonalDetails_Cbooking {
  display: flex;
}

.tableContentError {
  color: red;
  letter-spacing: 1px;
}
.personaldetails_dataForm {
  margin-bottom: 20px;
}
.personaldetails_dataForm input {
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.473);
  border-radius: 0.2rem;
  outline: none;
  width: 90%;
}
.dateofbirth_per {
  width: 100% !important;
}
.personaldetails_dataForm label {
  margin-bottom: 8px;
  font-size: 14px;
  letter-spacing: 1px;
  color: var(--main-color);
}
.personaldetails_dataForm p {
  color: rgba(0, 0, 0, 0.74);
}
.modal_btn Button {
  text-transform: capitalize !important;
}


@media only screen and (max-width: 768px) {
  .createBooking_table {
    width: 90%;
  }
  .createBooking_table .create_customer h3 {
    font-size: 14px;
  }
  .create_booking {
    font-size: 14px;
  }
  .booking_details h4 {
    font-size: 14px;
  }
  .personalDetails_banner h4 {
    font-size: 14px;
  }
  .textField_main {
    width: 40%;
    font-size: 9px !important;
  }
  .textField_mop {
    width: 90%;
    margin-top: 0;
  }
  .textField_main {
    font-size: 8px !important;
  }

  .createbooking_btn {
    margin-left: 9px;
    width: 100%;
  }
  .createbooking_btn Button {
    width: 100%;
    height: 35px;
    font-size: 15px;
    text-transform: capitalize;
  }
  .tableContentError {
    margin-top: 10px;
    font-size: 12px;
  }
  .createBooking_conatiner p {
    margin-top: 8px;
    font-size: 10px;
  }
  .row_add_booking .textField {
    width: 90%;
  }
  .createbook_input {
    width:100px;
    /* background-color: blue; */
  }
  .createbook_input Input::placeholder{
 font-size: 1px !important;
  }
  .box{
    width: 80% !important;
   
  }
  .box input{
    width: 40%;
  }
  .box label{
    font-size: 14px;
  }
  .box .modal_btn{
    width: 80% !important;
    font-size: 16px !important;
  }
}