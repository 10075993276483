.SahoTaxesForm_conatiner {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  margin-top: 1%;
}

.SahoTaxesForm_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 90%;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.SahoTaxesForm_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
  /* color: rgba(255, 255, 255, 0.692); */
  cursor: none;
}

.SahoTaxesForm_table tbody tr td input {
  margin-top: 10px;
}

.SahoTaxesForm_btn {
  text-align: center;
}
.SahoTaxesForm_btn Button {
  width: 40%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  background-color: var(--main-btncolor) !important;
}
.SahoTaxesForm_btn Button:hover {
  background-color: var(--main-hover) !important;
}
.addCircleIcon_tax,
.editIcon_tax,
.saveIcon_tax {
  /* color: #fcc14ce8 !important; */
  color: var(--main-btncolor) !important;
}
.addCircleIcon_tax:hover {
  /* color: #ffb219e3 !important; */
  color: var(--main-btncolor) !important;
}
.tax_error {
  color: red;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: capitalize;
}
@media only screen and (max-width: 768px) {
  .SahoTaxesForm_conatiner {
    margin-bottom: 80%;
  }
  .SahoTaxes_subTable thead tr th {
    font-size: 12px;
  }
  .input_tax td:nth-child(1) input {
    width: 130%;
  }
  .input_tax td:nth-child(2) input {
    width: 135%;
  }
  .input_tax td:nth-child(3) input {
    width: 60%;
  }
  .SahoTaxesForm_table h3 {
    font-size: 15px;
  }
  .addCircleIcon_tax,
  .editIcon_tax,
  .saveIcon_tax {
    font-size: 20px !important;
  }
  .SahoTaxesForm_btn Button {
    width: 60%;
    font-size: 12px;
  }
}
