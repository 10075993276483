/* Fonts for css */
@font-face {
  src: url("./fonts/gothic.ttf");
  font-family: "Gothic";
  font-weight: 800;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Gothic" !important  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
a:visited,
a:hover,
a:link {
  text-decoration: none;
}
/* code {
  font-family: "Gothic",source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* for the image background */
.Saho_mainContainer {
  background: url("./Components/style/image/bg.png");
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

/* To get "Gothic" font in input data */
input {
  font-family: "Gothic" !important  ;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  font-family: "Gothic" !important  ;
}

::-webkit-input-placeholder {
  font-size: 14px !important;
  font-family: "Gothic" !important  ;
  text-transform: capitalize;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px !important;
  font-family: "Gothic" !important  ;
  text-transform: capitalize;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px !important;
  font-family: "Gothic" !important  ;
  text-transform: capitalize;
}
/* For responsive */

@media only screen and (max-width: 768px) {
  ::-webkit-input-placeholder {
    font-size: 10px !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 10px !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 10px !important;
  }
}
/* For primary color */
:root {
  /* --main-color: #2b8ac9; */
  --main-color: #000077;
  --main-btncolor: #f7a440;
  --main-btncolor-hover: #f7a440;
  /* --main-color: #2F5D62; */
  /* --main-hover: #2b5a5fe0; */
  --main-hover: #1988d3;
}

/* For SahoHeader */
@import url("./Components/style/header.css");
/* For SahoAddNewCustomerForm */
@import url("./Components/style/SahoAddNewCustomerForm.css");
/* For SahoRegisterSubAgent */
@import url("./Components/style/SahoRegisterSubAgent.css");
/* For SahoTaxesForm */
@import url("./Components/style/SahoTaxesForm.css");
/* For SahoFooter */
@import url("./Components/style/footer.css");
/* For SahoCreateBooking */
@import url("./Components/style/SahoCreateBooking.css");
/* For SahoCreateList */
@import url("./Components/style/SahoCustomersForm.css");
/* For SahoLogin */
@import url("./Components/style/SahoLoginForm.css");
/* For SahoTransaction */
@import url("./Components/style/SahoTransaction.css");
/* For SahoAddCredit */
@import url("./Components/style/SahoAddCredit.css");
