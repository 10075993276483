.header_conatiner {
  display: flex;
  justify-content: space-between;
  height: 65px;
  position: sticky;
  top: 0;
  z-index: 200;
  
}

.header_logoConatiner {
  margin: 10px 0px 0px 55px;
  position: relative;
  width: 200px;
  cursor: pointer;
}

.header_logoConatiner .logo_conatiner {
  width: 235px;
  height: 45px;
  overflow: hidden;
}
.logo_conatiner img {
  width: 200px;
  height: 45px;
  object-fit: contain;
}

.header_helpline p {
  color: rgba(0, 0, 0, 0.897);
  
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 2px;
  
}
.header_helpline {
  display: flex;
  align-items: center;
  margin: 10px 60px 0 0;
  cursor: none;
}
.header_helpline p span {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 800;
}

@media only screen and (max-width: 768px) {
  .header_conatiner {
    position: relative;
  }

  .header_logoConatiner {
    margin: 10px 0px 0px 20px;
  }

  .logo_conatiner img {
    width: 95px;
    height: 45px;
  }
  .header_helpline p span {
    font-size: 8px;
  }
  .header_helpline p {
    font-size: 10px;
    font-weight: 600;
  }
  .header_helpline {
    margin: 10px 8px 0 0;
  }
}
