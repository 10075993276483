@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri&display=swap);
.header_conatiner {
  display: flex;
  justify-content: space-between;
  height: 65px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 200;
  
}

.header_logoConatiner {
  margin: 10px 0px 0px 55px;
  position: relative;
  width: 200px;
  cursor: pointer;
}

.header_logoConatiner .logo_conatiner {
  width: 235px;
  height: 45px;
  overflow: hidden;
}
.logo_conatiner img {
  width: 200px;
  height: 45px;
  object-fit: contain;
}

.header_helpline p {
  color: rgba(0, 0, 0, 0.897);
  
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 2px;
  
}
.header_helpline {
  display: flex;
  align-items: center;
  margin: 10px 60px 0 0;
  cursor: none;
}
.header_helpline p span {
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 800;
}

@media only screen and (max-width: 768px) {
  .header_conatiner {
    position: relative;
  }

  .header_logoConatiner {
    margin: 10px 0px 0px 20px;
  }

  .logo_conatiner img {
    width: 95px;
    height: 45px;
  }
  .header_helpline p span {
    font-size: 8px;
  }
  .header_helpline p {
    font-size: 10px;
    font-weight: 600;
  }
  .header_helpline {
    margin: 10px 8px 0 0;
  }
}


.newCustomer_conatiner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.newCustomer_table {
  width: 95%;
  height: 70%;
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.newCustomer_table h3 {
  text-align: center;
  text-transform: uppercase;
  color: var(--main-color);
  font-weight: 500;
  letter-spacing: 2px;
  
}
.newCustomer_addNewCustomer {
  background-color: rgba(247, 247, 247, 0.897);
  padding: 8px;
  padding-left: 9px;
  margin: 17px;
  color: rgba(0, 0, 0, 0.658);
}
.newCustomer_addNewCustomer h5 {
  color: rgba(0, 0, 0, 0.733);
  margin-left: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 20px;
}
.newCustomer_dataField {
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.newCustomer_Button {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
.newCustomer_Button Button {
  width: 40%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  color: white;
  background-color: var(--main-btncolor) !important;
}
.newCustomer_Button Button:hover {
  background-color:var(--main-hover) !important;
}
.agencyErr {
  color: red !important;
}
@media only screen and (max-width: 768px) {
  .newCustomer_Button Button {
    width: 60%;
    font-size: 15px;
  }
}

.spl_notification {
  font-family: 'Sans-Serif';
  text-align: center;
  color: #000077;             
  font-style: italic;
  font-weight: 800;
  font-size: 20px;
  margin-block-start: 5px;
  margin-block-end: 5px;
  margin-inline-start: 0px;
  width: 100%;
  display: block;
}

.credit_balance {
  font-family: "Gothic" !important;
  text-align: right;
  color: #000077;             
  font-style: bold;
  font-weight: 1200;
  font-size: 25px; 
  width: 100%;
  display: block;
  align-items: center;
  margin-block-start: -40px;
  margin-block-end: 12px;
  margin-inline-start: -5px;
}
.registerSubAgent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registerSubAgent_table input {
  outline: none !important;
  border-radius: 0.5rem !important;
}

.registerSubAgent_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 60%;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.registerSubAgent_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
  transition: 0.5s ease-in-out;
}
.registerSubAgent_table h3:hover {
  color: #1480c9;
}
.registerSubAgent_table textarea {
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.171);
  width: 96%;
  border-radius: 0.2rem;
  margin-bottom: 5px;
}
.register_select {
  width: 40.5ch;
  margin: 10px 0 10px 0;
  height: 30px !important;
  background-color: white !important;
  border-radius: 0.5rem !important;
}
.register_selectLabel {
  margin: 4px 0 2px 0;
}
.register_selectUserRole {
  width: 40.5ch;
  margin-top: 12px;
  height: 35px !important;
  background-color: white !important;
  border-radius: 0.5rem !important;
}
.registerSubAgent_table .gstin {
  margin-top: 28px;
}
.registerSubAgent_table .mb-3 {
  height: 32px;
}
.registerSubAgent_table p {
  font-size: 12px;
  color: rgba(255, 0, 0, 0.712);
  margin: 0.1px;
  text-transform: capitalize;
}
.registerSubAgent_table .iconBtn {
  margin-top: 13px;
}
.register_btn {
  margin-top: 8px;
  text-align: center;
}
.register_btn Button {
  width: 40%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  color: white;
  background-color: var(--main-btncolor) !important;
}

.circle_Btn {
  color: var(--main-btncolor) !important;
}

.userRole_regsub {
  color: var(--main-color);
  padding: 20px;
  border: 1px solid lightgray;
}
.userRole_regsub_heading {
  margin-top: 20px;
}
.userRole_regsub_container {
  display: flex;
}

/* For responsive */

@media only screen and (max-width: 768px) {
  .register_selectLabel h6 {
    font-size: 12px;
  }
  .registerSubAgent_table {
    margin-top: 2rem;
    margin-bottom: 5rem;
    width: 90%;
    padding: 2rem;
  }
  .registerSubAgent_table h3 {
    font-weight: 500;
    font-size: 18px;
  }
  .registerSubAgent_table textarea {
    resize: none;
    width: 92%;
    margin-bottom: 6px;
  }
  .register_select {
    width: 40.5ch;
    margin: 10px 0 10px 0;
    height: 10px !important;
    background-color: white !important;
    border-radius: 3px !important;
  }
  .register_selectLabel {
    margin: 4px 0 2px 0;
    font-size: 12px !important;
  }
  .register_select {
    width: 80% !important;
  }
  .userRole_reg {
    font-size: 12px !important;
  }

  .register_selectUserRole {
    width: 80% !important;
    border-radius: 0.5rem !important;
  }
  .userRole_regsub_heading h4 {
    font-size: 18px;
  }
  .register_btn Button {
    width: 70%;
    font-size: 12px;
    font-weight: 600;
    margin-top: 8px;
    color: white;
  }
  .registerSubAgent_table .iconBtn {
    margin-top: 6px;
  }
  .circle_Btn {
    font-size: 25px !important;
  }
  ::-webkit-input-placeholder {
    font-size: 5px !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 5px !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 5px !important;
  }
}

.SahoTaxesForm_conatiner {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  margin-top: 1%;
}

.SahoTaxesForm_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 90%;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.SahoTaxesForm_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
  /* color: rgba(255, 255, 255, 0.692); */
  cursor: none;
}

.SahoTaxesForm_table tbody tr td input {
  margin-top: 10px;
}

.SahoTaxesForm_btn {
  text-align: center;
}
.SahoTaxesForm_btn Button {
  width: 40%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  background-color: var(--main-btncolor) !important;
}
.SahoTaxesForm_btn Button:hover {
  background-color: var(--main-hover) !important;
}
.addCircleIcon_tax,
.editIcon_tax,
.saveIcon_tax {
  /* color: #fcc14ce8 !important; */
  color: var(--main-btncolor) !important;
}
.addCircleIcon_tax:hover {
  /* color: #ffb219e3 !important; */
  color: var(--main-btncolor) !important;
}
.tax_error {
  color: red;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: capitalize;
}
@media only screen and (max-width: 768px) {
  .SahoTaxesForm_conatiner {
    margin-bottom: 80%;
  }
  .SahoTaxes_subTable thead tr th {
    font-size: 12px;
  }
  .input_tax td:nth-child(1) input {
    width: 130%;
  }
  .input_tax td:nth-child(2) input {
    width: 135%;
  }
  .input_tax td:nth-child(3) input {
    width: 60%;
  }
  .SahoTaxesForm_table h3 {
    font-size: 15px;
  }
  .addCircleIcon_tax,
  .editIcon_tax,
  .saveIcon_tax {
    font-size: 20px !important;
  }
  .SahoTaxesForm_btn Button {
    width: 60%;
    font-size: 12px;
  }
}

.footer-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  width: 100%;
  height: 110px;
  /* position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000; */
}

.footer-conatiner h6 {
  margin-top: 18px;
  color: white;
}
.footer-conatiner p {
  
  color: white;
}
.footer-conatiner h6 span {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px;
}
@media only screen and (max-width: 768px) {
  .footer-conatiner{
    padding: 0px 10px 0 10px;
  }
  .footer-conatiner span {
    font-size: 10px;
    letter-spacing: 0px;
    
  }
  .footer-conatiner .CopyrightIcon {
    font-size: 12px !important;
  }
}

.createBooking_conatiner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createBooking_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 80%;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}

.create_booking {
  /* color: #2b8ac9; */
  color: var(--main-color);
  text-align: center;
  margin: 15px 0 20px 0;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.booking_details {
  width: 100%;
  height: 45px;
  background-color: rgba(211, 211, 211, 0.24);
  display: flex;
  align-items: center;
}
.booking_details h4 {
  color: rgba(0, 0, 0, 0.733);
  margin-left: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 20px;
}
.bookingDetails_table {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row_add_booking {
  display: flex;
}
.add_Passenger_btn {
  color: var(--main-color) !important;
  background-color: rgba(117, 215, 245, 0.164) !important;
  text-transform: capitalize !important;
}
.modal_bodybooking {
  display: flex;
  justify-content: space-between;
}
.modal_bodybooking input {
  outline: none;
  width: 60%;
  border-radius: 0.2rem;
  border: 0.5px solid gainsboro;
  padding: 3px;
}
.modal_bodybooking label {
  font-family: "Gothic" !important  ;
}
.modal_bodybooking_head {
  display: flex;
  justify-content: space-between;
}
/* for all textfeilds */
.textField {
  width: 35ch;
  margin: 10px !important;
}
.textField_main {
  width: 60%;
  font-size: 12px !important;
}
.createBooking_conatiner p {
  color: red;
  font-size: 14px;
}
.personalDetails_banner {
  width: 100%;
  height: 45px;
  background-color: rgba(211, 211, 211, 0.24);
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
}
.personalDetails_banner h4 {
  color: rgba(0, 0, 0, 0.733);
  margin-left: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 20px;
}

.personalDetails_table {
  padding: 20px;
}

.createbooking_btn {
  margin-top: 22px;
  text-align: center;
  width: 100%;
}
.createbooking_btn Button {
  width: 40%;
  height: 50px;
  font-size: 22px;
  color: white;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  background-color: var(--main-btncolor) !important;
  font-family: "Gothic";
}

.editPersonalDetails_Cbooking .editPersonalDetails_Input {
  width: 40%;
  height: 35px;
  outline: none;
  border: 1px solid gainsboro;
  border-radius: 0.2rem;
}
.editPersonalDetails_Cbooking {
  display: flex;
}

.tableContentError {
  color: red;
  letter-spacing: 1px;
}
.personaldetails_dataForm {
  margin-bottom: 20px;
}
.personaldetails_dataForm input {
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.473);
  border-radius: 0.2rem;
  outline: none;
  width: 90%;
}
.dateofbirth_per {
  width: 100% !important;
}
.personaldetails_dataForm label {
  margin-bottom: 8px;
  font-size: 14px;
  letter-spacing: 1px;
  color: var(--main-color);
}
.personaldetails_dataForm p {
  color: rgba(0, 0, 0, 0.74);
}
.modal_btn Button {
  text-transform: capitalize !important;
}


@media only screen and (max-width: 768px) {
  .createBooking_table {
    width: 90%;
  }
  .createBooking_table .create_customer h3 {
    font-size: 14px;
  }
  .create_booking {
    font-size: 14px;
  }
  .booking_details h4 {
    font-size: 14px;
  }
  .personalDetails_banner h4 {
    font-size: 14px;
  }
  .textField_main {
    width: 40%;
    font-size: 9px !important;
  }
  .textField_mop {
    width: 90%;
    margin-top: 0;
  }
  .textField_main {
    font-size: 8px !important;
  }

  .createbooking_btn {
    margin-left: 9px;
    width: 100%;
  }
  .createbooking_btn Button {
    width: 100%;
    height: 35px;
    font-size: 15px;
    text-transform: capitalize;
  }
  .tableContentError {
    margin-top: 10px;
    font-size: 12px;
  }
  .createBooking_conatiner p {
    margin-top: 8px;
    font-size: 10px;
  }
  .row_add_booking .textField {
    width: 90%;
  }
  .createbook_input {
    width:100px;
    /* background-color: blue; */
  }
  .createbook_input Input::placeholder{
 font-size: 1px !important;
  }
  .box{
    width: 80% !important;
   
  }
  .box input{
    width: 40%;
  }
  .box label{
    font-size: 14px;
  }
  .box .modal_btn{
    width: 80% !important;
    font-size: 16px !important;
  }
}
.customer_list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customerlist_table {
  margin-top: 1.5rem;
  margin-bottom: 5rem;
  width: 90%;
  padding: 2rem;

  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.customerlist_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
  cursor: none;
}
.customerlist_search {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 10px;
}
.customerlist_search .SearchBar {
  width: 30%;
}
.dataGrid_customerlist {
  /* border-color: aqua !important; */

  border: none !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
  background-color: rgba(180, 179, 179, 0.377) !important;
}
.MuiDataGrid-renderingZone {
  border: none !important;
}

/* Style for BookingList */
.root_booklist {
  display: flex;
  justify-content: center;
  height:800px;
  margin-top: -70px;
  margin-bottom: 8%;
}
.Bookinglist_heading {
  color: var(--main-color);
  letter-spacing: 0.2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.searchbar_bookingList {
  margin-left: 77%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  height: 30px !important;
}
.paperContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border: none !important;
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  background-color: white !important;
  box-shadow: -5px 3px 17px -5px rgba(0,0,0,0.75);
  margin-top: 90px;
}
.paperContainer h3{
  margin-top: 25px;

}
@media only screen and (max-width: 768px) {
  .customerlist_table h3 {
    font-size: 15px;
  }
  .customerlist_search .SearchBar {
    width: 60%;
    height: 40px;
  }
  .Bookinglist_heading {
    font-size: 15px;
    margin-bottom: 8px;
  }
  .searchbar_bookingList {
    width: 40%;
    height: 40px !important;
    margin-bottom: 12px;
    margin-right: 22px;
  }
}

.login_mainContainer {
  width: 100%;
  height: 81vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 40%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.login_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: var(--main-color);
  cursor: none;
}
.login_table label {
  color: var(--main-color);
  margin-bottom: 5px;
  font-size: 16px;
  /* color: rgba(0, 0, 0, 0.815); */
  letter-spacing: 0.1rem;
}
.input_login {
  color: var(--main-color) !important;
}

.login_table p {
  margin-top: 5px;
  font-size: 12px;
  color: rgba(255, 0, 0, 0.712);
}
.login_btn {
  margin-top: 8px;
  text-align: center;
  width: 100%;
}
.login_btn Button {
  width: 40%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  background-color:  var(--main-btncolor) !important;
  color: white;
}

.visibility_login,
.accountCircle_login {
  color: var(--main-btncolor) !important;
}
@media only screen and (max-width: 768px) {
 
  .login_table {
    width: 80%;
    margin-top: 5rem;
  }
  .MuiInputBase-root{
    height: 40px !important;
    width: 100% !important;
  }
  .login_table label {
    
    font-size: 13px;
  }
  .login_table h3 {
   font-size: 18px;
  }
 
  .login_btn Button {
    width: 60%;
    font-size: 13px;
    
  }
}

.SahoTransactionForm_conatiner {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.SahoTransactionForm_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 80%;
  padding: 2rem;
  object-fit: contain;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.SahoTransactionForm_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
}

.SahoTransactionForm_subTable tbody tr td input {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .SahoTransactionForm_conatiner {
    margin-bottom: 50%;
    
  }
  .SahoTransactionForm_subTable thead tr th {
    font-size: 8px !important;
  }
  .rows_tran {
    font-size: 10px !important;
  }
  .rows_tran th {
    padding-right: 18px !important;
  }
  .SahoTransactionForm_table {
    width: 100%;
    padding: 1rem;
  }
  .SahoTransactionForm_subTable {
    width: 1000px;
    display: flex;
    flex-direction: column;
  }
  .SahoTransactionForm_table h3 {
    font-size: 15px;
  }
  .addCircleIcon_tax,
  .editIcon_tax,
  .saveIcon_tax {
    font-size: 20px !important;
  }

  .SahoTransactionForm_subTable thead tr th {
    font-size: 12px;
  }

  .SahoTransactionForm_subTable tbody tr th {
    font-size: 12px;
  }
}

.SahoAddCredit_conatiner {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8%;
}

.SahoAddCredit_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 90%;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.SahoAddCredit_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
  /* color: rgba(255, 255, 255, 0.692); */
  cursor: none;
}

.SahoAddCredit_table tbody tr td input {
  margin-top: 10px;
}
.row_add {
  display: flex;
}
.row_col {
  width: 50%;
  padding-right: 1%;
}
.row_col2 {
  width: 50%;
}
.SahoAddCredit_btn {
  font-size: 13px;
  border-radius: 0.2rem;
  color: white;
  text-transform: uppercase;
  padding: 8px;
  height: 10%;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  background-color: var(--main-btncolor) !important;
}
.modal_btn Button {
  font-size: 13px;
  border-radius: 0.2rem;
  color: white !important;
  text-transform: uppercase;
  padding: 8px;
  height: 10%;
  font-weight: 700;
  letter-spacing: 3px;
  border: none !important;
  background-color: var(--main-btncolor) !important;
}
.modal_btn Button:hover {
  background-color: var(--main-btncolor-hover) !important;
}
.SahoAddCredit_btn:hover {
  background-color: var(--main-btncolor-hover) !important;
}
.SahoAddCredit_subTable input {
  outline: none;
  border-radius: 0.2rem;
  border: 0.5px solid gainsboro;
  padding: 2px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.SahoAddCredit_subTable input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal_body {
  display: flex;
  justify-content: space-between;
}
.modal_body input {
  outline: none;
  width: 60%;

  border-radius: 0.2rem;
  border: 0.5px solid gainsboro;
  padding: 3px;
}
.modal_body label {
  font-family: "Gothic" !important  ;
}
.modal_head {
  font-family: "Gothic" !important  ;
  font-weight: 700;
}

.modal_payment label {
  margin-right: 5%;
}
.modal_payment input {
  margin-right: 1%;
}
@media only screen and (max-width: 768px) {
  .SahoAddCredit_subTable thead tr th {
    font-size: 12px;
  }
  .SahoAddCredit_btn {
    font-size: 8px;
    height: 5%;
  }

  .SahoAddCredit_table h3 {
    font-size: 15px;
  }
  .addCircleIcon_tax,
  .editIcon_tax,
  .saveIcon_tax {
    font-size: 20px !important;
  }
  .SahoAddCredit_btn Button {
    width: 60%;
    font-size: 12px;
  }

  .SahoAddCredit_subTable thead tr th {
    font-size: 12px;
  }

  .SahoAddCredit_subTable tbody tr th {
    font-size: 12px;
  }
  .box label{
    font-size: 12px;

  }
}

/* Fonts for css */
@font-face {
  src: url(/static/media/gothic.23a9eb4c.ttf);
  font-family: "Gothic";
  font-weight: 800;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Gothic" !important  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
a:visited,
a:hover,
a:link {
  text-decoration: none;
}
/* code {
  font-family: "Gothic",source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* for the image background */
.Saho_mainContainer {
  background: url(/static/media/bg.1ed5c937.png);
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

/* To get "Gothic" font in input data */
input {
  font-family: "Gothic" !important  ;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  font-family: "Gothic" !important  ;
}

::-webkit-input-placeholder {
  font-size: 14px !important;
  font-family: "Gothic" !important  ;
  text-transform: capitalize;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px !important;
  font-family: "Gothic" !important  ;
  text-transform: capitalize;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px !important;
  font-family: "Gothic" !important  ;
  text-transform: capitalize;
}
/* For responsive */

@media only screen and (max-width: 768px) {
  ::-webkit-input-placeholder {
    font-size: 10px !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 10px !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 10px !important;
  }
}
/* For primary color */
:root {
  /* --main-color: #2b8ac9; */
  --main-color: #000077;
  --main-btncolor: #f7a440;
  --main-btncolor-hover: #f7a440;
  /* --main-color: #2F5D62; */
  /* --main-hover: #2b5a5fe0; */
  --main-hover: #1988d3;
}

/* For SahoHeader */
/* For SahoAddNewCustomerForm */
/* For SahoRegisterSubAgent */
/* For SahoTaxesForm */
/* For SahoFooter */
/* For SahoCreateBooking */
/* For SahoCreateList */
/* For SahoLogin */
/* For SahoTransaction */
/* For SahoAddCredit */

.MuiTableSortLabel-icon {
  color: "#FFFFFF" !important;
}
.navbar_conatiner {
  background-color: var(--main-color) !important;
  padding: 0 !important;
}
.makeStyles-listName-93 {
  font-family: "Gothic";
  color: red;
}
.nav_dpName {
  font-size: 18px !important;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.1rem;
  margin-top: 12px;
  color: rgba(255, 255, 255, 1);
  font-family: "Gothic";
}
.nav_dpName span {
  font-size: 14px;
  text-transform: capitalize;
  margin-right: 3px;
}
.nav_dpcreditamt {
  font-size: 16px;
  font-weight: 800;
  margin-top: -12px;
  color: rgba(67, 250, 67, 0.836);
  font-family: "Gothic";
}
.nav_dpcreditamt span {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.555);
  margin-right: 5px;
}
.navbar_conatiner h3 {
  margin-right: 8px;
  font-weight: 900;
  letter-spacing: 4px;
  color: rgba(0, 0, 0, 0.911);
  text-transform: uppercase;
}
.navbar_conatiner h3 span {
  color: #ffb219e3;
}
.MuiList-padding{
  padding: 0px !important;

}
.listLink {
  /* color: #35a2eb !important */
  color: var(--main-color);
}
.listLink:hover {
  /* color: #1480c9 !important; */
  color: var(--main-hover);
}
/* header */

.logo_conatiner {
  margin-top: 12px;
  width: 220px;
  height: 45px;
  overflow: hidden;
}
.logo_conatiner img {
  width: 170px;
  height: 30px;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .logo_conatiner {
    margin-top: 0px;
    width: 110px;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
   
  
  }
  .logo_conatiner img {
    width: 100px;
    height: 60px;
    
  }



  .nav_dpName {
    font-size: 12px;
  }

  .nav_dpcreditamt {
    font-size: 12px;
  }
}

