.login_mainContainer {
  width: 100%;
  height: 81vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 40%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.login_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: var(--main-color);
  cursor: none;
}
.login_table label {
  color: var(--main-color);
  margin-bottom: 5px;
  font-size: 16px;
  /* color: rgba(0, 0, 0, 0.815); */
  letter-spacing: 0.1rem;
}
.input_login {
  color: var(--main-color) !important;
}

.login_table p {
  margin-top: 5px;
  font-size: 12px;
  color: rgba(255, 0, 0, 0.712);
}
.login_btn {
  margin-top: 8px;
  text-align: center;
  width: 100%;
}
.login_btn Button {
  width: 40%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  background-color:  var(--main-btncolor) !important;
  color: white;
}

.visibility_login,
.accountCircle_login {
  color: var(--main-btncolor) !important;
}
@media only screen and (max-width: 768px) {
 
  .login_table {
    width: 80%;
    margin-top: 5rem;
  }
  .MuiInputBase-root{
    height: 40px !important;
    width: 100% !important;
  }
  .login_table label {
    
    font-size: 13px;
  }
  .login_table h3 {
   font-size: 18px;
  }
 
  .login_btn Button {
    width: 60%;
    font-size: 13px;
    
  }
}
