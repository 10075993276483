.SahoTransactionForm_conatiner {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.SahoTransactionForm_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 80%;
  padding: 2rem;
  object-fit: contain;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.SahoTransactionForm_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
}

.SahoTransactionForm_subTable tbody tr td input {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .SahoTransactionForm_conatiner {
    margin-bottom: 50%;
    
  }
  .SahoTransactionForm_subTable thead tr th {
    font-size: 8px !important;
  }
  .rows_tran {
    font-size: 10px !important;
  }
  .rows_tran th {
    padding-right: 18px !important;
  }
  .SahoTransactionForm_table {
    width: 100%;
    padding: 1rem;
  }
  .SahoTransactionForm_subTable {
    width: 1000px;
    display: flex;
    flex-direction: column;
  }
  .SahoTransactionForm_table h3 {
    font-size: 15px;
  }
  .addCircleIcon_tax,
  .editIcon_tax,
  .saveIcon_tax {
    font-size: 20px !important;
  }

  .SahoTransactionForm_subTable thead tr th {
    font-size: 12px;
  }

  .SahoTransactionForm_subTable tbody tr th {
    font-size: 12px;
  }
}
