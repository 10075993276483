.registerSubAgent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registerSubAgent_table input {
  outline: none !important;
  border-radius: 0.5rem !important;
}

.registerSubAgent_table {
  margin-top: 2rem;
  margin-bottom: 5rem;
  width: 60%;
  padding: 2rem;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri&display=swap");
.registerSubAgent_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
  transition: 0.5s ease-in-out;
}
.registerSubAgent_table h3:hover {
  color: #1480c9;
}
.registerSubAgent_table textarea {
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.171);
  width: 96%;
  border-radius: 0.2rem;
  margin-bottom: 5px;
}
.register_select {
  width: 40.5ch;
  margin: 10px 0 10px 0;
  height: 30px !important;
  background-color: white !important;
  border-radius: 0.5rem !important;
}
.register_selectLabel {
  margin: 4px 0 2px 0;
}
.register_selectUserRole {
  width: 40.5ch;
  margin-top: 12px;
  height: 35px !important;
  background-color: white !important;
  border-radius: 0.5rem !important;
}
.registerSubAgent_table .gstin {
  margin-top: 28px;
}
.registerSubAgent_table .mb-3 {
  height: 32px;
}
.registerSubAgent_table p {
  font-size: 12px;
  color: rgba(255, 0, 0, 0.712);
  margin: 0.1px;
  text-transform: capitalize;
}
.registerSubAgent_table .iconBtn {
  margin-top: 13px;
}
.register_btn {
  margin-top: 8px;
  text-align: center;
}
.register_btn Button {
  width: 40%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  color: white;
  background-color: var(--main-btncolor) !important;
}

.circle_Btn {
  color: var(--main-btncolor) !important;
}

.userRole_regsub {
  color: var(--main-color);
  padding: 20px;
  border: 1px solid lightgray;
}
.userRole_regsub_heading {
  margin-top: 20px;
}
.userRole_regsub_container {
  display: flex;
}

/* For responsive */

@media only screen and (max-width: 768px) {
  .register_selectLabel h6 {
    font-size: 12px;
  }
  .registerSubAgent_table {
    margin-top: 2rem;
    margin-bottom: 5rem;
    width: 90%;
    padding: 2rem;
  }
  .registerSubAgent_table h3 {
    font-weight: 500;
    font-size: 18px;
  }
  .registerSubAgent_table textarea {
    resize: none;
    width: 92%;
    margin-bottom: 6px;
  }
  .register_select {
    width: 40.5ch;
    margin: 10px 0 10px 0;
    height: 10px !important;
    background-color: white !important;
    border-radius: 3px !important;
  }
  .register_selectLabel {
    margin: 4px 0 2px 0;
    font-size: 12px !important;
  }
  .register_select {
    width: 80% !important;
  }
  .userRole_reg {
    font-size: 12px !important;
  }

  .register_selectUserRole {
    width: 80% !important;
    border-radius: 0.5rem !important;
  }
  .userRole_regsub_heading h4 {
    font-size: 18px;
  }
  .register_btn Button {
    width: 70%;
    font-size: 12px;
    font-weight: 600;
    margin-top: 8px;
    color: white;
  }
  .registerSubAgent_table .iconBtn {
    margin-top: 6px;
  }
  .circle_Btn {
    font-size: 25px !important;
  }
  ::-webkit-input-placeholder {
    font-size: 5px !important;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 5px !important;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 5px !important;
  }
}
