
.newCustomer_conatiner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.newCustomer_table {
  width: 95%;
  height: 70%;
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.newCustomer_table h3 {
  text-align: center;
  text-transform: uppercase;
  color: var(--main-color);
  font-weight: 500;
  letter-spacing: 2px;
  
}
.newCustomer_addNewCustomer {
  background-color: rgba(247, 247, 247, 0.897);
  padding: 8px;
  padding-left: 9px;
  margin: 17px;
  color: rgba(0, 0, 0, 0.658);
}
.newCustomer_addNewCustomer h5 {
  color: rgba(0, 0, 0, 0.733);
  margin-left: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 20px;
}
.newCustomer_dataField {
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.newCustomer_Button {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
.newCustomer_Button Button {
  width: 40%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  border: none;
  color: white;
  background-color: var(--main-btncolor) !important;
}
.newCustomer_Button Button:hover {
  background-color:var(--main-hover) !important;
}
.agencyErr {
  color: red !important;
}
@media only screen and (max-width: 768px) {
  .newCustomer_Button Button {
    width: 60%;
    font-size: 15px;
  }
}

.spl_notification {
  font-family: 'Sans-Serif';
  text-align: center;
  color: #000077;             
  font-style: italic;
  font-weight: 800;
  font-size: 20px;
  margin-block-start: 5px;
  margin-block-end: 5px;
  margin-inline-start: 0px;
  width: 100%;
  display: block;
}

.credit_balance {
  font-family: "Gothic" !important;
  text-align: right;
  color: #000077;             
  font-style: bold;
  font-weight: 1200;
  font-size: 25px; 
  width: 100%;
  display: block;
  align-items: center;
  margin-block-start: -40px;
  margin-block-end: 12px;
  margin-inline-start: -5px;
}