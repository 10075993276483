.footer-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  width: 100%;
  height: 110px;
  /* position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000; */
}

.footer-conatiner h6 {
  margin-top: 18px;
  color: white;
}
.footer-conatiner p {
  
  color: white;
}
.footer-conatiner h6 span {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px;
}
@media only screen and (max-width: 768px) {
  .footer-conatiner{
    padding: 0px 10px 0 10px;
  }
  .footer-conatiner span {
    font-size: 10px;
    letter-spacing: 0px;
    
  }
  .footer-conatiner .CopyrightIcon {
    font-size: 12px !important;
  }
}
