.customer_list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customerlist_table {
  margin-top: 1.5rem;
  margin-bottom: 5rem;
  width: 90%;
  padding: 2rem;

  background-color: white;
  box-shadow: 1px -2px 17px -5px rgba(0, 0, 0, 0.212);
}
.customerlist_table h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--main-color);
  cursor: none;
}
.customerlist_search {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 10px;
}
.customerlist_search .SearchBar {
  width: 30%;
}
.dataGrid_customerlist {
  /* border-color: aqua !important; */

  border: none !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
  background-color: rgba(180, 179, 179, 0.377) !important;
}
.MuiDataGrid-renderingZone {
  border: none !important;
}

/* Style for BookingList */
.root_booklist {
  display: flex;
  justify-content: center;
  height:800px;
  margin-top: -70px;
  margin-bottom: 8%;
}
.Bookinglist_heading {
  color: var(--main-color);
  letter-spacing: 0.2rem;
  font-weight: 700;
  text-transform: uppercase;
}
.searchbar_bookingList {
  margin-left: 77%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  height: 30px !important;
}
.paperContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border: none !important;
  width: 80%;
  height: fit-content;
  padding: 20px;
  background-color: white !important;
  box-shadow: -5px 3px 17px -5px rgba(0,0,0,0.75);
  margin-top: 90px;
}
.paperContainer h3{
  margin-top: 25px;

}
@media only screen and (max-width: 768px) {
  .customerlist_table h3 {
    font-size: 15px;
  }
  .customerlist_search .SearchBar {
    width: 60%;
    height: 40px;
  }
  .Bookinglist_heading {
    font-size: 15px;
    margin-bottom: 8px;
  }
  .searchbar_bookingList {
    width: 40%;
    height: 40px !important;
    margin-bottom: 12px;
    margin-right: 22px;
  }
}
